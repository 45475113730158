<script>
  export let devicedata;
  export let id;
  export let name;
</script>

<div class="card">
  <div class="card-content">
    <div class="media">
      <div class="media-left">
        <img
          src="icons/{id.replaceAll(' ', '-')}-icon.svg"
          alt="icon"
          style="height:40px"
        />
      </div>
      <div class="media-content">
        <p class="title">{name}</p>
      </div>
    </div>
    <table class="table is-hoverable is-fullwidth">
      <tbody>
        {#each devicedata as item}
          <tr>
            <td>
              <a href={item.link}>
                <img
                  src="link-icon.svg"
                  alt="icon"
                  style="height:15px; float:right; margin-top:5px;"
                />
                <figure>
                  <h2>{item.name}</h2>
                  <figcaption style="color:grey;">
                    <small>{item.link.split("://").slice(1)}</small>
                  </figcaption>
                </figure>
              </a>
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
</div>

<style>
  @media screen and (max-width: 768px) {
    .media-content {
      overflow-x: visible !important; /* Override Bulma to remove tiny scrollbar on title when making page smaller*/
    }
  }
  a {
    color: black;
    text-decoration: none;
  }
</style>
