<script>
	import {onMount} from 'svelte'
  import Assets from './Assets.svelte'
  import Verticals from './Verticals.svelte'
  import DemoDevices from './DemoDevices.svelte'
  import Videos from './Videos.svelte'
  import { Tabs, TabList, TabPanel, Tab } from './tabs/index.js'

  let devicedata
  let assetdata
  let verticaldata
  let videodata
  
	/*Fetching data from json files*/
	onMount(() => {
		fetch('https://cdn.ftnt.io/wise/devices.json', { cache: "no-cache" })
			.then(res => res.json())
			.then(json => {
				devicedata = json.map(item => {
					return item
				})
			})
    fetch('https://cdn.ftnt.io/wise/assets.json', { cache: "no-cache" })
			.then(res => res.json())
			.then(json => {
				assetdata = json.map(item => {
					return item
				})
			})
    fetch('https://cdn.ftnt.io/wise/verticals.json', { cache: "no-cache" })
			.then(res => res.json())
			.then(json => {
				verticaldata = json.map(item => {
					return item
				})
			})
    fetch('https://cdn.ftnt.io/wise/videos.json', { cache: "no-cache" })
			.then(res => res.json())
			.then(json => {
				videodata = json.map(item => {
					return item
				})
			})
	})

</script>

<main>
  <!--Plausible Tracking-->
  <script defer data-domain="pods.ftnt.io" src="https://plausible.ftnt.io/js/script.js"></script>
  
<!-- <pre><code>{JSON.stringify(data, null, 2)}</code></pre>  -->
  <div class="" id="content" >
		<!--Logo-->
    <div class="container pt-5">
       <div style="display: flex; justify-content: flex-end"><img src="Fortinet_logo.svg" alt="fortinet" style="height:28px"></div>
      
     <!--Tabs-->
     <Tabs>
      <TabList>
        <Tab>Assets</Tab>
        <Tab>Verticals</Tab>
        <Tab>Demo Devices</Tab>
        <Tab>Videos</Tab>
      </TabList>

    <!--Content-->
      <TabPanel>
        <Assets {assetdata} />
      </TabPanel>

      <TabPanel>
        <Verticals {verticaldata} />
      </TabPanel>
    
      <TabPanel>
        <DemoDevices {devicedata} />
      </TabPanel>

      <TabPanel>
        <Videos {videodata} />
      </TabPanel>
    </Tabs>
    
    <!--Footer-->
		<div class="columns is-multiline is-centered mb-6 is-6-desktop">
      <div class="has-text-centered">
        <br>
        <br>
        <small><span class="has-text-grey-dark">Created by the TME dev team. Questions?</span>
    <a class="is-info" href="mailto:tmg@fortinet.com">Contact Us</a>.</small>
      </div>
  </div>
  <div class="container pt-5">
	</div>
 
</main>

<style>
 main {
  background-color: #f7f7f7;
 }
</style>