<script>
  import DeviceCard from "./components/DeviceCard.svelte";

  export let devicedata;
</script>

<br />

{#if devicedata}
  <div class="columns is-multiline is-mobile">
    <div class="column">
      <DeviceCard devicedata={devicedata.filter(obj=> obj.pillar === "Secure Networking")} id="Secure Networking" name="Secure Networking"/>
    </div>
    <div class="column">
      <DeviceCard devicedata={devicedata.filter(obj=> obj.pillar === "Cloud Security")} id="Cloud Security" name="Secure Application Journey"/>
      <br />
      <DeviceCard devicedata={devicedata.filter(obj=> obj.pillar === "Zero Trust Access")} id="Zero Trust Access" name="Zero Trust Access"/>
    </div>
    <div class="column">
      <DeviceCard devicedata={devicedata.filter(obj=> obj.pillar === "Security Operations Center")} id="Security Operations Center" name="Security Operations Center"/>
      <br />
      <DeviceCard devicedata={devicedata.filter(obj=> obj.pillar === "Network Operations Center")} id="Network Operations Center" name="Network Operations Center"/>
    </div>
  </div>
{/if}

<style>
  .column {
    min-width: 375px;
  }
</style>
