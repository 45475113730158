<script>
    import Accordion from './components/Accordion.svelte'

    export let videodata

    var pillars = [
        {
            "name":"Secure Networking",
            "slug":"Secure Networking",
            "icon":"Secure-Networking-icon.svg"
        },
        {
            "name":"Secure Application Journey",
            "slug":"Cloud Security",
            "icon":"Cloud-Security-icon.svg"
        },
        {
            "name":"Zero Trust Access",
            "slug":"Zero Trust Access",
            "icon":"Zero-Trust-Access-icon.svg"
        },
        {
            "name":"Security Operations Center",
            "slug":"Security Operations",
            "icon":"Security-Operations-Center-icon.svg"
        },
        {
            "name":"Network Operations Center",
            "slug":"Network Operations",
            "icon":"Network-Operations-Center-icon.svg"
        },
        {
            "name":"Operational Technology",
            "slug":"Operational Technology",
            "icon":"OT-icon.svg"
        }
    ]
</script>

{#if videodata}
    <br>
    {#each pillars as pillar}
        <div class="box">
            <Accordion open={true}>
                <div class="media" slot="head">
                    <div class="media-left">
                        &nbsp; &nbsp; <img src="icons/{pillar.icon}" alt="icon" style="height:40px">
                    </div>
                    <div class="media-content">
                        <h1 class="title"> 
                            {pillar.name}
                        </h1>
                    </div>
                    <div class="media-right">
                        <svg style="tran"  width="20" height="20" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M9 5l7 7 7-7"></path></svg>
                    </div>
                </div>
                <div slot="details" class="flex-container">
                    {#each videodata as item}
                        {#if item.pillar == pillar.slug}
                            {#if item.intro_skip}
                                <a href={"https://youtu.be/" + item.youtube_id + "?t=8"}> 
                                    <img src="https://img.youtube.com/vi/{item.youtube_id}/mq2.jpg" alt="thumbnail" width="225px">
                                    <div style="width:215px; text-align:center; margin-top:5px;">
                                        <small>{item.name}</small>
                                    </div>
                                </a>
                            {:else}
                                <a href={"https://youtu.be/" + item.youtube_id}> 
                                    <img src="https://img.youtube.com/vi/{item.youtube_id}/mq2.jpg" alt="thumbnail" width="225px">
                                    <div style="width:215px; text-align:center; margin-top:5px;">
                                        <small>{item.name}</small>
                                    </div>
                                </a>
                            {/if}
                        {/if}
                    {/each}
                </div>
            </Accordion>
        </div>
    {/each}
{/if}

<style>
.flex-container {
    display: flex;
    flex-wrap: wrap;
}
a {
    padding: 10px;
    color: black;
    text-decoration: none;
    border-radius: 3px;
    line-height: 1rem;
}
a:hover {
    background-color: #f2f2f2;
}
svg {
    transition: transform 0.2s ease-in;
}

</style>