<script>
  import AssetCard from "./components/AssetCard.svelte";

  export let verticaldata;

  let assetdata = verticaldata;
</script>

{#if verticaldata}
  <br />
  <div class="columns is-multiline is-mobile">
    <div class="column">
      <AssetCard assetdata={assetdata.filter(obj=> obj.pillar === "OT")} id="OT" name="Operational Technology" />
    </div>
    <div class="column">
      <AssetCard assetdata={assetdata.filter(obj=> obj.pillar === "Retail")} id="Retail" name="Retail" />
    </div>
    <div class="column">
      <AssetCard assetdata={assetdata.filter(obj=> obj.pillar === "Healthcare")} id="Healthcare" name="Healthcare" />
    </div>
  </div>
{/if}

<style>
  .column {
    min-width: 375px;
  }
</style>
