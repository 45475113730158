<script>
  import Modal, { getModal } from "./modal.svelte";
  import QrCode from "svelte-qrcode";

  export let assetdata;
  export let id;
  export let name;
</script>

<div class="card">
  <div class="card-content">
    <div class="media">
      <div class="media-left">
        <img
          src="icons/{id.replaceAll(" ", "-")}-icon.svg"
          alt="icon"
          style="width:40px"
        />
      </div>
      <div class="media-content">
        <p class="title">{name}</p>
      </div>
    </div>
    <table class="table is-fullwidth is-narrow">
      <tbody>
        {#each assetdata as item}
            <Modal id={item.name}>
              <div class="has-text-centered" style="width:405px">
                <h2>{item.name}</h2>
              </div>
              <QrCode value={item.link} size="400" />
              <div class="has-text-centered" style="width:405px">
                <small>
                  <span class="has-text-grey-dark">{item.link}</span></small
                >
              </div>
            </Modal>
            <tr>
              <td>
                <div class="flex-container">
                  <a href={item.link} class="itemName"><h2>{item.name}</h2></a>
                  <button
                    class="button is-text qrcode"
                    on:click={() => getModal(item.name).open()}
                    ><span class="icon"
                      ><img src="qrcode-solid.svg" alt="qrcode" /></span
                    ></button
                  >
                </div>
              </td>
            </tr>
        {/each}
      </tbody>
    </table>
  </div>
</div>

<style>
      @media screen and (max-width: 768px) {
    .media-content {
      overflow-x: visible !important; /* Override Bulma to remove tiny scrollbar on title when making page smaller*/
    }
  }
  .flex-container {
    display: flex;
  }
  .itemName {
    flex: 1;
    padding: 5px;
    border-radius: 4px;
  }
  .itemName:hover {
    cursor: pointer;
    background-color: #f7f7f7;
  }
  .icon {
    flex-shrink: 0; /* Prevent shrinking */
    width: 15px;
    align-items: top;
  }
  button {
    padding: none;
    margin: 0 0 0 0;
  }
  a {
    color: black;
    text-decoration: none;
  }
  .card .media:not(:last-child) {
    margin-bottom: 0.5rem;
  }
</style>