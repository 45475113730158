<script>
  import AssetCard from "./components/AssetCard.svelte";

  export let assetdata;


</script>

{#if assetdata}
  <br />
  <div class="columns is-multiline is-mobile">
    <div class="column">
      <AssetCard assetdata={assetdata.filter(obj=> obj.pillar === "General")} id="General" name="Fortinet Documents"/>
      <br />
      <AssetCard assetdata={assetdata.filter(obj=> obj.pillar === "Secure Networking")} id="Secure Networking" name="Secure Networking"/>
    </div>
    <div class="column">
      <AssetCard assetdata={assetdata.filter(obj=> obj.pillar === "Cloud Security")} id="Cloud Security" name="Secure Application Journey"/>
      <br />
      <AssetCard assetdata={assetdata.filter(obj=> obj.pillar === "Zero Trust Access")} id="Zero Trust Access" name="Zero Trust Access"/>
    </div>
    <div class="column">
      <AssetCard assetdata={assetdata.filter(obj=> obj.pillar === "Security Operations Center")} id="Security Operations Center" name="Security Operations Center"/>
    </div>
  </div>
{/if}

<style>
  .column {
    min-width: 375px;
  }
</style>
